import has from 'lodash/has';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import React from 'react';

import { useDeviceInfoContext } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import type { IFilters } from '@src/@types/microcredits';
import { PageHeaderFilters } from '@src/components/details/components/PageHeaderFilters';
import { SimplePageHeaderFilters } from '@src/components/PageHeader';
import { SimplePageHeaderPhone } from '@src/components/PageHeader/SimplePageHeaderPhone';
import { Experiments } from '@src/constants/abTest';
import { useCreditsExtraFiltersVisibility } from '@src/hooks/useCreditsExtraFiltersVisibility';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { useHasUtmLabel } from '@src/reducers/route';
import { checkIsFullDealBlockVisible } from '@src/utils/checkIsFullDealBlockVisible';
import { isNaKartuPage, isPartner2GisPage, isSpecialPageWithoutQuery } from '@src/utils/routing';

import { FullDealEntrypoint } from './components';

interface Props {
    filters: IFilters;
    difference?: number;
    hasBanner: boolean;
    seo?: Partial<Seo>;
    onChange: (value: any, name: string) => void;
}

export const PageHeaderBrandingFilters: FC<Props> = ({ filters, seo, difference, hasBanner, onChange }) => {
    const { asPath, query } = useRouter();
    const [pathWithoutQuery] = asPath.split('?');

    const hasUtmLabel = useHasUtmLabel();
    const [conditionsActive] = useCreditsExtraFiltersVisibility();

    const { phone: isPhone } = useDeviceInfoContext();

    const { isAVariant } = useTestVariant(Experiments.FULL_DEAL_ENTRY_BLOCK);

    const isFullDealBlockVisible =
        isPhone && isAVariant && checkIsFullDealBlockVisible(pathWithoutQuery) && !hasUtmLabel;

    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const isCRMSpecial = isSpecial && has(query, 'landing_type');
    const isPartner2Gis = isPartner2GisPage(asPath);

    const showNewFilters = isNaKartuPage(pathWithoutQuery);

    if (isFullDealBlockVisible) {
        return <FullDealEntrypoint filters={filters} seo={seo} onChange={onChange} />;
    }

    const isPhoneInputShown = (isSpecial && !isCRMSpecial) || pathWithoutQuery === '/zaimy/' || isPartner2Gis;

    if (isPhoneInputShown) {
        return (
            <div className={hasBanner ? undefined : 'h-mt-20'}>
                <SimplePageHeaderPhone />
            </div>
        );
    }

    if (showNewFilters) {
        return <PageHeaderFilters initialAmount={filters.amount} initialTerm={filters.term} onChange={onChange} />;
    }

    return (
        <SimplePageHeaderFilters
            filters={filters}
            difference={difference}
            conditionsActive={conditionsActive}
            onChange={onChange}
            seo={seo}
        />
    );
};
