type SpecialHeaderText = {
    header: string;
    subHeader: string;
};

export const getSpecialHeaderText = (landingType: string): SpecialHeaderText => {
    switch (landingType) {
        case '1':
            return {
                header: 'Займы с высокой вероятностью одобрения',
                subHeader: 'Могут одобрить даже с плохой кредитной историей.',
            };

        case '2':
            return {
                header: 'Займы без дополнительных услуг',
                subHeader:
                    'Выбрать займ бывает сложно.\n' +
                    'Поэтому мы проверили проверку — сами взяли займы во всех МФО. В этой подборке — займы без дополнительных услуг, чтобы вы не переплачивали за то, что вам не нужно.',
            };

        case '3':
            return {
                header: 'Займы с высокой вероятностью одобрения',
                subHeader: 'Ваша персональная подборка займов готова.',
            };

        case '4':
            return {
                header: 'Новый займ без переплат',
                subHeader: 'Эти МФО дают займы без процентов для новых клиентов.',
            };

        default:
            return {
                header: 'Займы онлайн от лучших МФО',
                subHeader: '',
            };
    }
};
