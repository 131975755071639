import add from 'date-fns/add';
import { useRouter } from 'next/router';
import type { FormEvent } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { Button, MaskInput, Typography } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import {
    DESKTOP_BUTTON_SIZE,
    MAX_MOBILE_NUMBER_CHAR_COUNT,
    MOBILE_BUTTON_SIZE,
    PHONE_INPUT_LABEL,
} from '@src/components/PageHeader/consts';
import { API_PATH } from '@src/config/routes';
import { sendPhoneNumberEvent } from '@src/helpers/analyticsEvents';
import { useFakePixelClickLink } from '@src/hooks/useFakePixelClickLink';
import { Cookie, CookieKey } from '@src/modules/cookie';
import { useUser } from '@src/reducers/user';
import { getYandexMetricsIdJS } from '@src/utils/analytics';

import styles from './styles.module.scss';

const { Text, Heading } = Typography;

export const SimplePageHeaderPhone = React.memo(() => {
    const isMobile = useIsMobile();
    const router = useRouter();
    const { fetchFakePixelClick } = useFakePixelClickLink();

    const phoneNumberFromCookie = Cookie.get()._PN_;
    const userId = useUser()?.sub || null;
    const clientIdYm = getYandexMetricsIdJS(false) || null;

    const [phone, setPhone] = useState(phoneNumberFromCookie ?? '');
    const [errorMessage, setErrorMessage] = useState('');
    const [focus, setFocus] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleBlur = useCallback(() => {
        setFocus(false);
    }, [setFocus]);

    const handleFocus = useCallback(() => {
        setFocus(true);
    }, [setFocus]);

    const handleChangePhone = useCallback(
        (event: FormEvent<HTMLInputElement>) => {
            const newValue = event.currentTarget.value.replace(/[^0-9]/g, '').replace(/^7/, '+7');
            setPhone(newValue);
            setErrorMessage('');
        },
        [setPhone],
    );

    const validatePhoneNumber = useCallback((phoneNumber: string) => {
        const phoneNumberRegex = /^\+79\d{9}$/;
        return phoneNumberRegex.test(phoneNumber);
    }, []);

    const handleSendPhone = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            setFormSubmitted(true);

            if (phone === '+7' || phone.length === 0) {
                setErrorMessage('Заполните поле');
                return;
            }

            if (phone.length !== MAX_MOBILE_NUMBER_CHAR_COUNT || !validatePhoneNumber(phone)) {
                setErrorMessage('Введите корректный номер телефона');
            }
        },
        [phone, validatePhoneNumber],
    );

    useEffect(() => {
        if (errorMessage.length === 0 && formSubmitted) {
            setIsDisabled(true);
            fetch(`${API_PATH}/save-phone`, {
                method: 'POST',
                body: JSON.stringify({ phone, userId, clientIdYm }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        sendPhoneNumberEvent();
                        router.reload();
                    }
                })
                .catch((error) => {
                    throw new Error(error);
                })
                .finally(async () => {
                    setIsDisabled(false);
                    Cookie.set(CookieKey._PN_, phone, { expires: add(new Date(), { minutes: 10 }) });

                    await fetchFakePixelClick();
                });
        }

        return () => {
            setFormSubmitted(false);
        };
    }, [clientIdYm, errorMessage.length, fetchFakePixelClick, formSubmitted, phone, router, userId]);

    return (
        <div className={styles.container}>
            <Heading level={4}>Узнайте, кто одобрит займ*</Heading>
            <div className={styles.wrapper}>
                <div className={styles.phoneInput}>
                    <MaskInput
                        label={PHONE_INPUT_LABEL}
                        mask="+7 (999) 999-99-99"
                        onChange={handleChangePhone}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={phone}
                        invalid={!focus && Boolean(errorMessage)}
                        inputMode="tel"
                    />
                </div>
                {Boolean(errorMessage) && !focus && isMobile && (
                    <Text className={styles.errorMessage} size={12}>
                        {errorMessage}
                    </Text>
                )}
                <Button
                    variant="primary"
                    size={isMobile ? MOBILE_BUTTON_SIZE : DESKTOP_BUTTON_SIZE}
                    onClick={handleSendPhone}
                    className={styles.button}
                    disabled={isDisabled}
                >
                    Показать
                </Button>
            </div>

            {Boolean(errorMessage) && !focus && !isMobile && (
                <Text className={styles.errorMessage} size={12}>
                    {errorMessage}
                </Text>
            )}

            <Text className={styles.footerText} size={10}>
                Вводя номер телефона и нажимая кнопку “Показать”, вы подтверждаете ознакомление и соглашаетесь с
                <a
                    href="https://www.sravni.ru/promo/soglasie-na-obrabotku-personalnyh-dannyh/"
                    target="_blank"
                    rel="noreferrer"
                >
                    &nbsp;Согласием на обработку персональных данных
                </a>
            </Text>
        </div>
    );
});
